const theme = {
    mainFont: "'Roboto', 'Rahoma', sans-serif",
    styledFont: "'Georgia', 'Roboto', sans-serif",
    greenColor: '#245428',
    dirtyWhiteColor: '#F3F5F5',
    white: '#ffffff',
    darkColor: '#222222',
    greyColor: '#333333',
    lightGreyColor: '#939090',
    mobile: '1080px'
}

export default theme;