import styled from "styled-components";

const H2 = styled.h2`
color: ${({color}) => color ? color : "#000"};
font-size: 16px;
font-weight: 100;
text-decoration: none;
line-height: 2rem;
text-align: ${({align}) => align ? align : null};

@media screen and (min-width: 600px){
 margin: 0.5rem 0;
}

@media screen and (min-width: 750px){
 margin: 0.5rem 0;
 font-size: 22px;
}

@media screen and (min-width: 1100px){
 margin: 0.5rem 0;
}
`

export default H2