import styled from "styled-components";

const FooterSection = styled.footer`
background-color: ${({theme}) => theme.darkColor};
position: fixed;
bottom: 0;
left: 0;
right: 0;
width: 100%;
height: 10vh;
z-index: -1;
padding: 0 1rem;
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;

@media screen and (min-width: 1100px){
padding: 0 2rem;
}ó

`

const FooterImg = styled.img`
height: 50%;
`

export {FooterImg, FooterSection}