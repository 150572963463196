import React from 'react';
import {useTranslation} from "react-i18next";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import Section from "../components/styled/PageSection";
import {StyledButton} from "../components/ContactForm.styled";
import {Link} from "react-router-dom";
import {ErrorSection} from "./Error.styled";

const Error = () => {
    const {t} = useTranslation();
    return (
        <Section>
            <Navbar dark={true}/>
            <ErrorSection>
                <Header title={"404 ERROR"} subtitle={t("error.somethingWentWrong")}/>
                <Link to={"/"}>
                    <StyledButton>
                        {t("error.goHome")}
                    </StyledButton>
                </Link>
            </ErrorSection>
        </Section>
    );
};

export default Error;
