import React from 'react';
import * as PropTypes from "prop-types";
import {ImgContainer, StyledLink} from "./LinkImage.styled";

const LinkImage = ({src, text, link}) => {
    return (
        <ImgContainer img={src}>
            <h1>
                <StyledLink to={link}>
                    <svg>
                        <rect x="0" y="0" fill="none" width="100%" height="100%"/>
                    </svg>
                    {text}
                </StyledLink>
            </h1>
        </ImgContainer>
    );
};

LinkImage.propTypes = {
    src: PropTypes.string.isRequired,
    text: PropTypes.string,
    link: PropTypes.string.isRequired
};

export default LinkImage;
