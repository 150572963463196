import React, {Component} from 'react';
import {GoogleApiWrapper, InfoWindow, Map, Marker} from 'google-maps-react';
import constants from "../const";

const mapStyles = {
    position: 'absolute',
    width: '100%',
    height: '50vh',
    marginBottom: '10vh',
    overflow: "hidden"
};

class GoogleMap extends Component {
    state = {
        showingInfoWindow: true,
        activeMarker: {},
        selectedPlace: {}
    };
    onMarkerClick = (props, marker) =>
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });

    onClose = () => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    };

    render() {
        return (
            <div style={{position: 'relative', width: '100%', height: '50vh', overflow: "hidden"}}>
                <Map
                    google={this.props.google}
                    zoom={14}
                    style={mapStyles}
                    initialCenter={{lat: 50.098597, lng: 22.070334}}
                >
                    <Marker
                        onClick={this.onMarkerClick}
                        name={constants.googleMapInfo} position={{lat: 50.098597, lng: 22.070334}}/>
                    <InfoWindow
                        marker={this.state.activeMarker}
                        visible={this.state.showingInfoWindow}
                        onClose={this.onClose}
                    >
                        <div>
                            <h4>{this.state.selectedPlace.name}</h4>
                        </div>
                    </InfoWindow>
                </Map>
            </div>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyA0RS64C0N5Ayl5Je8ezyyHpFxNkoCqgrc',
    language: navigator.language
})(GoogleMap);