import React from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import Burger from "./Burger";
import LanguageHandler from "./LanguageHandler";
import {CatalogLink, StyledLink, StyledMenu} from "./MobileMenu.styled";
import Catalog from "../files/Katalog_produktow.pdf"

const MobileMenu = ({open, scroll, setOpen}) => {
    const {t} = useTranslation();
    return (
        <>
            <Burger open={open} scroll={scroll} setOpen={setOpen}/>
            <StyledMenu open={open}>
                <LanguageHandler/>
                <StyledLink to={"/"} onClick={() => setOpen(false)}>{t('menu.home')}</StyledLink>
                <StyledLink to={"/production/"} onClick={() => setOpen(false)}>{t('menu.production')}</StyledLink>
                <StyledLink to={"/services"} onClick={() => setOpen(false)}>{t('menu.services')}</StyledLink>
                <StyledLink to={"/contact"} onClick={() => setOpen(false)}>{t('menu.contact')}</StyledLink>
                <CatalogLink href={Catalog} onClick={() => setOpen(false)}>{t('menu.catalog')}</CatalogLink>
            </StyledMenu>
        </>
    )
}


MobileMenu.propTypes = {
    open: PropTypes.bool.isRequired,
    scroll: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired
};

export default MobileMenu;