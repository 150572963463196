import styled from "styled-components";

const P = styled.p`
color: ${({color}) => color ? color : "#000"};
font-size: 14px;
font-weight: 100;
text-align: ${({align}) => align ? align : null};
text-transform: none;
line-height: ${(props) => props.height || null};

@media screen and (min-width: 768px){
font-size: 16px;
}

@media screen and (min-width: 1100px){
}
`

export default P