import i18n from 'i18next';
import {initReactI18next} from "react-i18next";
import translationPL from "./pl.json"
import translationEN from "./en.json"

const resources = {
    pl: {
        translation: translationPL
    },
    en: {
        translation: translationEN
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: navigator.language,
        fallbackLng: "pl",
        interpolation: {
            escapeValue: false
        }
    });

export default i18n