import React from 'react';
import {Figure, StyledImage} from "./Image.styled";

const Image = ({className, src, text}) => {
    return (
        <Figure className={className} text={text}>
            <StyledImage src={src}/>
        </Figure>
    );
};

Image.propTypes = {};

export default Image;
