import styled from "styled-components";

const StyledImage = styled.img`
transition: .5s;
background-size: cover;
background: center;
width: 100%;
height: 100%;

@media screen and (min-width: 768px){
}

@media screen and (min-width: 1100px){
max-height: none;
}

&:hover{
transform: scale(1.1);
}
`

const Figure = styled.figure`
  position: relative;
  overflow: hidden;
  height: 100%;
  
  &::before {
    position: absolute;
    top: 0;
    left: -75%;
    z-index: 2;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
    background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
    -webkit-transform: skewX(-25deg);
    transform: skewX(-25deg);
}

  &::after {
    content: '${props => props.text || null}';
  ${({text}) => text && `
    background-color: rgba(186, 180, 180, 60%);
  `}
;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 25px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    line-height: 100%;
    transition: .5s;

  }

&:hover::after {
  opacity: 0;
  font-size: 2px;
}

&:hover::before {
  -webkit-animation: shine .75s;
  animation: shine .75s;

@-webkit-keyframes shine {
    100% {
      left: 100%;
    }
}
@keyframes shine {
    100% {
      left: 100%;
    }
}
`

export {StyledImage, Figure}