import React from 'react';
import P from "./styled/P";
import theme from "../theme";
import FSC from "../images/footer-fsc.png"
import {FooterImg, FooterSection} from "./Footer.styled";

const Footer = () => {
    return (
        <FooterSection>
            <P color={theme.dirtyWhiteColor}>Copyright 2020 &copy; monti-drewno.pl</P>
            <FooterImg src={FSC} alt={"FSC logo"}/>
        </FooterSection>
    );
};

export default Footer;
