const constants = {
    phoneNumber: "+48 796 114 531",
    workHours: "7:00 - 16:00",
    email: "montidrewno@gmail.com",
    address: "Terliczka 110, 36-001 Trzebownisko",
    googleMapInfo: "FPUH \"MONTI\"\n" +
        "Chmaj Marcin\n" +
        "Terliczka 110, 36-001 Trzebownisko",
}

export default constants;