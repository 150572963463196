import styled from "styled-components";

const Section = styled.section`
background-color: ${({theme}) => theme.dirtyWhiteColor};
padding: 2rem;
position: relative;

@media screen and (min-width: 1100px){
padding: 2rem 6rem;
}

@media screen and (min-width: 1400px){
padding: 2rem 9rem;
}
`

export {Section}