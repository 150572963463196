import React from 'react';
import Hero from "../images/production-hero.jpg"
import PictureSection from "../components/PictureSection";
import Line from "../components/styled/Line";
import Header from "../components/Header";
import theme from "../theme";
import {useTranslation} from "react-i18next";
import {Fade, Flip} from "react-reveal";
import WhiteSection from "../components/WhiteSection";
import Navbar from "../components/Navbar";
import ArmChair from "../images/production-frames.JPG"
import Plywood from "../images/production-plywood.JPG"
import Kitchen from "../images/production-kitchen.JPG"
import Stairs from "../images/production-stairs.jpg"
import Displays from "../images/production-displays.JPG"
import Furniture from "../images/production-wood-furniture.jpg"
import Legs from "../images/production-legs.JPG"
import Laser from "../images/production-laser.jpg"
import UV from "../images/production-uv.JPG"
import Steel from "../images/production-metal.png"
import Glass from "../images/production-glass.jpg"
import Image from "../components/Image";
import GridImage from "../components/styled/GridImage";
import Section from "../components/styled/PageSection";
import {GridFurniture, GridMaterials, GridWoodenElements} from "./Production.styled";

const Production = () => {
    const {t} = useTranslation();

    return (
        <Section>
            <Navbar dark={true}/>
            <PictureSection
                img={Hero}
                padding={'12% 10%'}
            >
                <div>
                    <Header
                        subtitle={t("production.hero")}
                        line={true}
                        align={'center'}
                        color={theme.dirtyWhiteColor}/>
                    <Flip left>
                        <Line/>
                    </Flip>
                </div>
            </PictureSection>
            <WhiteSection>
                <Line color={theme.darkColor}/>
                <Header subtitle={t("production.text")} align={'center'}/>
            </WhiteSection>
            <WhiteSection>
                <Fade>
                    <GridMaterials>
                        <GridImage className={"first"} src={Laser} text={t("production.laser")}/>
                        <Image src={UV} text={t("production.printing")}/>
                        <Image src={Steel} text={t("production.steel")}/>
                        <GridImage className={"last"} src={Glass} text={t("production.glass")}/>
                    </GridMaterials>
                </Fade>
            </WhiteSection>
            <WhiteSection>
                <Header align={'left'} title={t("production.woddenElements")}
                        subtitle={t("production.woddenElementsDescription")}/>
                <Fade>
                    <GridWoodenElements>
                        <Image src={ArmChair} text={t("production.woodFrames")}/>
                        <Image src={Legs} text={t("production.woodenLegs")}/>
                        <GridImage className={"plywood"} src={Plywood} text={t("production.plywood")}/>
                    </GridWoodenElements>
                </Fade>
            </WhiteSection>
            <WhiteSection>
                <Header align={'left'} title={t("production.woodenFurniture")}/>
                <Fade>
                    <GridFurniture>
                        <GridImage className={"displays"} src={Displays} text={t("production.displays")}/>
                        <GridImage className={"stairs"} src={Stairs} text={t("production.stairs")}/>
                        <GridImage className={"kitchen"} src={Kitchen} text={t("production.kitchenAccessories")}/>
                        <GridImage className={"loft"} src={Furniture} text={t("production.loftFurniture")}/>
                    </GridFurniture>
                </Fade>
            </WhiteSection>
        </Section>
    );
};

Production.propTypes = {};

export default Production;
