import {useEffect} from 'react';

export const useOnClickOutside = (ref, handler) => {
    useEffect(() => {
            const listener = event => {
                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }
                handler(event);
            };
            document.addEventListener('mousedown', listener);
            return () => {
                document.removeEventListener('mousedown', listener);
            };
        },
        [ref, handler],
    );
};

export const useWidthScreenListener = (setWidth) => {
    useEffect(() => {
        window.addEventListener("resize", () => setWidth(window.innerWidth));
    });
}

export const useScrollPositionListener = (scroll, setScroll) => {
    const listenScrollEvent = () => {
        if (window.scrollY > window.innerHeight - 100) {
            setScroll(true)
        } else {
            setScroll(false)
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);
    });
}


