import styled from "styled-components";

const Line = styled.div`
    width: ${(props) => props.width || "40%"};
    height: 0.05rem;
    background-color: ${(props) => props.color || "#fff"};
    margin: auto;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    
`

export default Line