import styled from "styled-components";

const LanguageLink = styled.a`
  text-decoration: none;
  text-transform: uppercase;
  font-size: 16px;
  padding: 1rem 0 1rem 1rem;
  min-width: 40%;
  text-align: right;
  transition:  .5s;
  position: relative;
  color: black;
  
    &:hover {
      color: ${({theme}) => theme.greenColor};
      transform: translateY(-2px);
      cursor: pointer;
    }
   
`

const Container = styled.div`
  top: 0;
  right: 20px;
  display: flex;
  flex-direction: row;
`

export {Container, LanguageLink}