import styled from "styled-components";
import {Link} from "react-router-dom";

const ImgContainer = styled.div`
overflow: hidden;
height: 300px;
position: relative;
z-index: 100;
background: url(${props => props.img}) center/cover no-repeat;

@media screen and (min-width: 1000px){
height: 350px;
}

@media screen and (min-width: 1200px){
height: 400px;
}

h1{
  color: white;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: right;
}
`
const StyledLink = styled(Link)`
  top: 80%;
  right: 10%;
  background-color: ${({theme}) => theme.greenColor};
  opacity: .9;
  padding: 0.7rem 3rem;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 100;
  font-size: 14px;
  position: relative;
  font-family: Roboto, sans-serif;
  
svg {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0; 
    width: 100%; 
  }
  
  rect {
    fill: none;
    stroke: transparent;
    stroke-width: 2;
    stroke-dasharray: 422, 0;
    transition: all 0.35s linear;
  }
}

  &:hover,
   &:focus{
  
  transform: scale(1.2);
  
  rect {
    stroke-width: 5;
    stroke-dasharray: 15, 310;
    stroke-dashoffset: 48;
    transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
    stroke: white;
  }
}
`

export {StyledLink, ImgContainer, }