import React from 'react';
import i18n from '../translations/i18n';
import {Container, LanguageLink} from "./LanguageHandler.styled";


const LanguageHandler = () => {
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }

    return (
        <Container>
            <LanguageLink onClick={() => changeLanguage('pl')}>PL</LanguageLink>
            <LanguageLink onClick={() => changeLanguage('en')}>EN</LanguageLink>
        </Container>
    );
};

export default LanguageHandler;
