import styled from "styled-components";

const H1 = styled.h1`
color: ${({color}) => color ? color : "#000"};
font-size: 32px;
font-weight: 100;
text-decoration: none;
line-height: 2rem;
margin: 0.5rem 0;
padding: 0.7rem 0;
position: relative;
border-bottom: 1.5px solid ${({theme}) => theme.lightGreyColor};

@media screen and (min-width: 600px){
 margin: 1.5rem 0;
}

@media screen and (min-width: 900px){
 margin: 1.5rem 0;
}

@media screen and (min-width: 1100px){
 margin: 1.5rem 0;
}
`
export default H1