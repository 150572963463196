import React from 'react';
import {Section} from "./WhiteSection.styled";

const WhiteSection = ({children}) => {
    return (
        <Section>
            {children}
        </Section>
    );
};

export default WhiteSection;
