import React from 'react';
import {Route, Switch} from "react-router";
import Home from "./pages/Home";
import Production from "./pages/Production";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import Error from "./pages/Error";
import './translations/i18n';
import {ThemeProvider} from "styled-components";
import theme from "./theme";
import {GlobalStyles} from "./global";
import Footer from "./components/Footer";

function App() {
    return (
        <ThemeProvider theme={theme}>
            <>
                <GlobalStyles/>
                <Switch>
                    <Route exact path={"/"} component={Home}/>
                    <Route exact path={"/production/"} component={Production}/>
                    <Route exact path={"/services"} component={Services}/>
                    <Route exact path={"/contact"} component={Contact}/>
                    <Route component={Error}/>
                </Switch>
            </>
            <Footer/>
        </ThemeProvider>
    )
}

export default App;
