import styled from "styled-components";

const FlexContainer = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
padding-bottom: 2rem;
`

export default FlexContainer;

