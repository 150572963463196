import React from 'react';
import Navbar from "../components/Navbar";
import WhiteSection from "../components/WhiteSection";
import {useTranslation} from "react-i18next";
import Line from "../components/styled/Line";
import theme from "../theme";
import H2 from "../components/styled/H2";
import FlexContainer from "../components/styled/FlexContainer";
import P from "../components/styled/P";
import constants from "../const";
import Box from "../components/styled/Box";
import ContactForm from "../components/ContactForm";
import GoogleMap from "../components/GoogleMap";
import {
    ContactHeaderName,
    ContactLocation,
    ContactLocationLine,
    DetailsContainer,
    HeaderContainer
} from "./Contact.styled";
import ContactSection from "../components/styled/ContactSection";

const Contact = () => {
    const {t} = useTranslation();
    return (
        <ContactSection>
            <Navbar dark={true}/>
            <WhiteSection>
                <HeaderContainer>
                    <ContactLocation>
                        {t("contact.country")}
                        <ContactLocationLine color={theme.lightGreyColor}/>
                        {t("contact.city")}
                    </ContactLocation>
                    <ContactHeaderName>{t("contact.name")}</ContactHeaderName>
                </HeaderContainer>
            </WhiteSection>
            <WhiteSection>
                <DetailsContainer>
                    <FlexContainer>
                        <H2>{t("contact.contactUs")}</H2>
                        <Line width={"100%"} color={"black"}/>
                        <FlexContainer>
                            <P height={"30px"}>{t("contact.phone") + constants.phoneNumber}</P>
                            <P height={"30px"}>{t("contact.workDays") + constants.workHours}</P>
                            <P height={"30px"}>{constants.email}</P>
                        </FlexContainer>
                    </FlexContainer>
                    <FlexContainer>
                        <H2>{t("contact.visitUs")}</H2>
                        <Line width={"100%"} color={"black"}/>
                        <FlexContainer>
                            <P height={"30px"}>{constants.address}</P>
                        </FlexContainer>
                    </FlexContainer>
                </DetailsContainer>
                <Box>{t("contact.leaveMessage")}</Box>
                <Box><ContactForm/></Box>
            </WhiteSection>
            <GoogleMap/>
        </ContactSection>
    );
};

Contact.propTypes = {};

export default Contact;
