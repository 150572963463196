import styled from "styled-components";
import {Link} from "react-router-dom";

const StyledLink = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  font-size: 24px;
  color: #000;
  transition: .5s;
  padding: 0.5rem 0;
  min-width: 40%;
  text-align: right;
  
    &:hover {
      color: ${({theme}) => theme.greenColor};
      transform: translateY(-2px);
    }
`

const CatalogLink = styled.a`
  text-decoration: none;
  text-transform: uppercase;
  font-size: 24px;
  color: #000;
  transition: .5s;
  padding: 0.5rem;
  margin-top: 0.5rem;
  text-align: right;
  border: 2px solid;
  
    &:hover {
      color: ${({theme}) => theme.greenColor};
      transform: translateY(-2px);
      cursor: pointer;
    }
  
`

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  background: #fff;
  opacity: .9;
  height: 60%;
  text-align: left;
  padding: 2rem;
  position: fixed;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${({open}) => open ? 'translateY(0)' : 'translateY(-100%)'};
  
  @media screen and (min-width: 760px){
  padding: 4rem;
  }
  
  @media (max-width: ${({theme}) => theme.mobile}) {
    width: 100%;
  }
`

export {StyledLink, StyledMenu, CatalogLink}