import styled from "styled-components";

const Nav = styled.nav`
  left: 0;
  top: 0;
  padding: 1.5rem 2rem;
  height: 16vh;
  width: 100%;
  display: flex;
  position: fixed;
  justify-content: space-between;
  align-items: baseline;
  z-index: 2500;
  transition: background-color 1.5s;
  
  &.dark{
  background: none};
  }
  
  @media screen and (min-width: 321px){
  height: 7rem;
  }
  
  @media screen and (min-width: 760px){
  padding: 1rem 4rem 2rem;
  height: 8rem;
  }
  
  @media screen and (min-width: 1024px){
  padding: 2rem 4rem;
  height: 10rem
  }
`

export {Nav}