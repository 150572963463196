import React from 'react';
import PropTypes from 'prop-types';
import {StyledBurger} from "./Burger.styled";

const Burger = ({open, scroll, setOpen}) => {
        return (
            <StyledBurger open={open} scroll={scroll} onClick={() => setOpen(!open)}>
                <div/>
                <div/>
                <div/>
            </StyledBurger>
        );
    }
;

Burger.propTypes = {
    open: PropTypes.bool.isRequired,
    scroll: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired
};

export default Burger;