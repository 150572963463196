import styled from "styled-components";
import {Link} from "react-router-dom";

const StyledLogo = styled.img`
  height: 100%;
  z-index: 2000;
  
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
`

const LogoLink = styled(Link)`
  height: 100%;
  z-index: 2000;
`

export {StyledLogo, LogoLink}