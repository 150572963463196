import React, {useEffect} from 'react';
import HomeHeroImg from "../images/home-hero.jpg"
import {useTranslation} from "react-i18next";
import gsap from "gsap";
import theme from "../theme";
import Line from "./styled/Line";
import PictureSection from "./PictureSection";
import H2 from "./styled/H2";
import {HomeCover, HomeHeader} from "./HomeHero.styled";

const HomeHero = () => {
    const {t} = useTranslation();
    const tl = gsap.timeline();

    useEffect(() => {
        tl.fromTo("#underHero", 1, {
            minHeight: '100vh',
            opacity: 1
        }, {transform: 'translateY(+110vh)', minHeight: 0})
        tl.delay(1.2)
        tl.fromTo("#underHero", 0.1, {opacity: 1}, {opacity: 0})
        tl.fromTo("#scroll, #h1, #h2", 1, {y: -50, opacity: 0}, {y: 0, opacity: 1})
        tl.fromTo("#scroll", 0.7, {y: 0}, {y: -5, yoyo: true, repeat: -1})
        tl.fromTo("#line", 1, {width: 0}, {width: 150}, "-=2")
    });


    return (
        <>
            <HomeCover id="underHero"><H2 color={theme.dirtyWhiteColor}>{t('home.furniture')}</H2></HomeCover>
            <PictureSection id="header" img={HomeHeroImg} height={'100vh'} padding={'40vh 0 0 0'}>
                <div>
                    <HomeHeader id="h1">{t('home.welcomeHero')}</HomeHeader>
                    <Line id="line"/>
                </div>
                <p id="scroll">{t('home.scroll')}</p>
            </PictureSection>
        </>
    );
};

export default HomeHero;
