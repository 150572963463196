import React from 'react';
import {useTranslation} from "react-i18next";
import i18n from "../translations/i18n";
import PropTypes from "prop-types";
import {CatalogLink, LanguageLink, StyledLink, StyledList} from "./NavList.styled";
import Catalog from "../files/Katalog_produktow.pdf"


const NavList = ({dark}) => {
    const {t} = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }

    return (
        <StyledList dark={dark}>
            <div>
                <StyledLink to={"/"}>{t('menu.home')}</StyledLink>
                <StyledLink to={"/production/"}>{t('menu.production')}</StyledLink>
                <StyledLink to={"/services"}>{t('menu.services')}</StyledLink>
                <StyledLink to={"/contact"}>{t('menu.contact')}</StyledLink>
                <CatalogLink href={Catalog}>{t('menu.catalog')}</CatalogLink>
            </div>
            <div>
                <LanguageLink onClick={() => changeLanguage('pl')}>PL</LanguageLink>
                <LanguageLink onClick={() => changeLanguage('en')}>EN</LanguageLink>
            </div>
        </StyledList>
    );
};

NavList.propTypes = {
    dark: PropTypes.bool.isRequired
};

export default NavList;
