import styled from "styled-components";
import {Link} from "react-router-dom";

const StyledList = styled.div`
width: 85vw;
display: flex;
justify-content: space-between;
flex-direction: row;
padding-left: 3vw;
color: ${({theme, dark}) => dark ? theme.darkColor : theme.dirtyWhiteColor};
border-color: ${({theme, dark}) => dark ? theme.darkColor : theme.dirtyWhiteColor};
div {
display: inline-block;
}
`

const StyledLink = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  font-size: 12px;
  color: inherit;
  transition: .5s;
  padding: 0.5rem 1.15rem;
  min-width: 40%;
  
    &:hover {
      color: ${({theme}) => theme.greenColor};
      transform: translateY(-2px);
      border-bottom: ${({theme}) => theme.greenColor} solid 1px;
    }
    
    @media screen and (min-width: 768px){
      font-size: 14px;
    }
    
    @media screen and (min-width: 900px){
      font-size: 16px;
    }
`

const LanguageLink = styled.a`
  text-decoration: none;
  text-transform: uppercase;
  font-size: 12px;
  color: inherit;
  transition: .5s;
  padding: 0.5rem 1.15rem;
  min-width: 40%;
  
    &:hover {
      color: ${({theme}) => theme.greenColor};
      transform: translateY(-2px);
      border-bottom: ${({theme}) => theme.greenColor} solid 1px;
      cursor: pointer;
    }
    
    @media screen and (min-width: 768px){
      font-size: 14px;
    }
    
    @media screen and (min-width: 900px){
      font-size: 16px;
    }
`

const CatalogLink = styled.a`
  font-size: 12px;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: inherit;
  transition: .5s;
  border: 2px solid;
  padding: 8px 12px;
  margin-left: 20px;
  
  &:hover {
  color: ${({theme}) => theme.white};
  background-color: ${({theme}) => theme.greenColor};
  border-color: ${({theme}) => theme.greenColor};
  cursor: pointer;
  }
  
   @media screen and (min-width: 768px){
      font-size: 14px;
    }
    
    @media screen and (min-width: 900px){
      font-size: 16px;
    }
  
`

export {StyledLink, StyledList, CatalogLink, LanguageLink}