import styled from "styled-components";

const ServiceNameContainer = styled.div`

margin: 1rem 0;

@media screen and (min-width: 750px){
width: 80%;
}
`

export {ServiceNameContainer}
