import styled from "styled-components";
import Line from "../components/styled/Line";

const HeaderContainer = styled.div`
display: flex;
flex-direction: column;
width: 80vw;
`

const ContactHeaderName = styled.h1`
font-size: 36px;
font-family: "Roboto Thin", Tahoma, sans-serif;
font-weight: 100;
`

const ContactLocation = styled.div`
font-size: 12px;
font-family: "Roboto Thin", Tahoma, sans-serif;
font-weight: 100;
color: ${({theme}) => theme.lightGreyColor};
display: flex;
flex-direction: row;
justify-content: space-between;
width: 150px;
text-transform: uppercase;

`

const DetailsContainer = styled.div`
display: flex;
flex-direction: column;
font-weight: 500;
text-transform: uppercase;
`

const ContactLocationLine = styled(Line)`
width: 20%;
`

export {ContactLocationLine, HeaderContainer, ContactHeaderName, ContactLocation, DetailsContainer}