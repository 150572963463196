import styled from "styled-components";
import Image from "../Image";

const GridImage = styled(Image)`

img {
    object-fit: cover;
}

@media screen and (min-width: 700px){
    &.displays{
        grid-row-start: 1;
        grid-row-end: 5;
        grid-column-start: 1;
        grid-column-end: 4;
    }
    &.loft{
        grid-row-start: 1;
        grid-row-end: 6;
        grid-column-start: 4;
        grid-column-end: 6;
    }
    &.stairs{
        grid-row-start: 5;
        grid-row-end: 9;
        grid-column-start: 1;
        grid-column-end: 4;
    }
    &.kitchen{
        grid-row-start: 6;
        grid-row-end: 9;
        grid-column-start: 4;
        grid-column-end: 6;
    }
    &.plywood{
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 3;
    }
}

@media screen and (min-width: 1024px){
    &.first{
        grid-row-start: 1;
        grid-row-end: 3;
        }
    &.last{
        grid-row-start: 1;
        grid-row-end: 3;
        grid-column-start: 3;
        grid-column-end: 4;
        }
    &.plywood{
        grid-row-start: unset;
        grid-row-end: unset;
        grid-column-start: unset;
        grid-column-end: unset;
         }
    }
`;

export default GridImage