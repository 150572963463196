import React from 'react';
import HomeHero from "../components/HomeHero";
import WhiteSection from "../components/WhiteSection";
import {useTranslation} from "react-i18next";
import Production from "../images/home-production.jpg"
import Services from "../images/home-services.jpg"
import Background from "../images/home-text.jpg"
import Wood from "../images/home-wood.png"
import Accuracy from "../images/home-accuracy.png"
import LinkImage from "../components/LinkImage";
import PictureSection from "../components/PictureSection";
import Line from "../components/styled/Line";
import Header from "../components/Header";
import theme from "../theme";
import H2 from "../components/styled/H2";
import P from "../components/styled/P";
import Strong from "../components/styled/Strong";
import Image from "../components/Image";
import {Fade, Flip} from "react-reveal";
import Navbar from "../components/Navbar";
import FlexContainer from "../components/styled/FlexContainer";
import {GridProducts, GridValues} from "./Home.styled";

const Home = () => {
    const {t} = useTranslation();
    return (
        <>
            <Navbar/>
            <HomeHero/>
            <WhiteSection>
                <Header title={t("home.ourOffer")}/>
                <GridProducts>
                    <Fade left>
                        <LinkImage
                            src={Production}
                            text={t("menu.production")}
                            link={"/production"}/>
                    </Fade>
                    <Fade right>
                        <LinkImage
                            src={Services}
                            text={t("menu.services")}
                            link={"/services"}/>
                    </Fade>
                </GridProducts>
            </WhiteSection>
            <PictureSection
                img={Background}
                padding={'15% 10%'}
            >
                <div>
                    <Header
                        subtitle={t('home.explanation')}
                        align={'center'}
                        color={theme.dirtyWhiteColor}/>
                    <Flip left>
                        <Line/>
                    </Flip>
                </div>

            </PictureSection>
            <WhiteSection>
                <Header title={t('home.values')}/>
                <GridValues>
                    <Fade left>
                        <FlexContainer>
                            <Image src={Wood}/>
                            <H2><Strong>{t('home.specialityWoodHeader')}</Strong></H2>
                            <P>{t('home.specialityWoodContent')}<br/><strong>{t('home.specialityWoodContentStrong')}</strong></P>
                        </FlexContainer>
                    </Fade>
                    <Fade right>
                        <FlexContainer>
                            <Image src={Accuracy}/>
                            <H2><Strong>{t('home.accuracyHeader')}</Strong></H2>
                            <P>{t('home.accuracyContent')}</P>
                        </FlexContainer>
                    </Fade>
                </GridValues>
            </WhiteSection>
        </>
    )
        ;
};

export default Home;
