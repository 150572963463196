import styled from "styled-components";

const GridValues = styled.div`
display: grid;
grid-gap: 10px;

div{
overflow: hidden;
}

@media screen and (min-width: 700px){
grid-template-columns: 1fr 1fr;
grid-column-gap: 20px;

@media screen and (min-width: 900px){
grid-column-gap: 10vw;
}

@media screen and (min-width: 1200px){
grid-column-gap: 15vw;
}
`

const GridProducts = styled.div`
display: grid;
grid-gap: 10px;

@media screen and (min-width: 768px){
grid-template-columns: 1fr 1fr;
grid-gap: 20px;
}
`

export {GridProducts, GridValues}