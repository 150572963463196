import React from 'react';
import PropTypes from 'prop-types';
import Header from "./Header";
import {ServiceNameContainer} from "./ServiceName.styled";

const ServiceName = ({title, subtitle}) => {
    return (
        <ServiceNameContainer>
           <Header title={title} subtitle={subtitle}/>
        </ServiceNameContainer>
    );
};

ServiceName.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
};

export default ServiceName;
