import styled from "styled-components";

const Section = styled.header`
  min-height: ${props => props.height};
  height: ${props => props.height};
  background: linear-gradient(to bottom, rgba(0, 0, 0, .25), rgba(0, 0, 0, .25)), url(${props => props.img}) center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: ${props => props.padding};
  z-index: 100;
  
  p {
  align-items: baseline;
  color: white;
  font-weight: 100;
  font-size: 12px;
  animation: up 3s infinite;
  padding: 1rem;
  }
}
`

export {Section}