import styled from "styled-components";
import {Button, Form, Input} from "reactstrap";

const StyledInput = styled(Input)`
  width: 100%;
  line-height: 30px;
  border: none;
  border-bottom: 1px solid black;
`

const StyledForm = styled(Form)`
  width: 100%;
`

const StyledButton = styled(Button)`
background-color: ${({theme}) => theme.darkColor};
border: none;
color: ${({theme}) => theme.dirtyWhiteColor};
padding: 1rem 2rem;
text-transform: capitalize;
margin-top: 1rem;
transition: .5s;

&:hover{
background-color: ${({theme}) => theme.greenColor};
cursor: pointer;
}
`
export {StyledInput, StyledButton, StyledForm}