import styled from "styled-components";

const Section = styled.div`
margin-top: 7rem;
  
  @media screen and (min-width: 760px){
  margin-top: 8rem;
  }
  
  @media screen and (min-width: 1024px){
  margin-top: 10rem;
  }
`
export default Section;