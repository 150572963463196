import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import LogoWhite from "../images/logo-white.png"
import LogoBlack from "../images/logo-black.png"
import NavList from "./NavList"
import MobileMenu from "./MobileMenu";
import {useOnClickOutside, useScrollPositionListener, useWidthScreenListener} from "../hooks";
import {Nav} from "./Navbar.styled";
import Logo from "./Logo";

const Navbar = ({dark}) => {
    const [width, setWidth] = useState(window.innerWidth);
    const [open, setOpen] = useState(false)
    const [scroll, setScroll] = useState(false)
    const breakpoint = 1080;
    const node = useRef();

    useOnClickOutside(node, () => setOpen(false))
    useWidthScreenListener(setWidth)
    useScrollPositionListener(scroll, setScroll)

    if (dark) {
        return (
            <Nav className={"dark"} dark={dark} ref={node}><Logo src={LogoBlack} alt="Logo Monti"/>
                {width > breakpoint ? <NavList dark={dark}/> :
                    <MobileMenu open={open} scroll={dark} setOpen={setOpen}/>}
            </Nav>
        );
    }

    return (
        <Nav id={"nav"} className={scroll ? "dark" : null} dark={scroll} ref={node}>{open || scroll ?
            <Logo src={LogoBlack} alt="Logo Monti"/> :
            <Logo src={LogoWhite} alt="Logo Monti"/>}
            {width > breakpoint ? <NavList dark={scroll}/> :
                <MobileMenu open={open} scroll={scroll} setOpen={setOpen}/>}
        </Nav>
    );
};

Navbar.propTypes = {
    dark: PropTypes.bool
};

export default Navbar;
