import styled from "styled-components";

const GridMaterials = styled.div`
display: grid;
grid-gap: 10px;
grid-template-columns: 1fr;
grid-template-rows: repeat(4, 1fr);
max-height: 170vh;

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    
    @media screen and (min-width: 1024px){
      max-height: unset;
    }
}

div {
overflow: hidden;
}

@media screen and (min-width: 700px){
grid-template-columns: repeat(2, 1fr);
grid-template-rows: repeat(2, 1fr);
grid-gap: 10px;
max-height: 60vh;
}

@media screen and (min-width: 1024px){
grid-template-columns: repeat(3, 1fr);
grid-template-rows: repeat(2, 1fr);
grid-gap: 10px;
max-height: 700px;
}
`
const GridWoodenElements = styled.div`
display: grid;
grid-gap: 10px;
position: relative;
max-height: 200vw;
grid-template-columns: 1fr;
grid-template-rows: repeat(3, 1fr);


@media screen and (min-width: 700px){
max-height: 70vw;
grid-template-columns: repeat(2, 1fr);
grid-template-rows: repeat(2, 1fr);
}

@media screen and (min-width: 1024px){
height: 33vw;
grid-template-columns: repeat(3, 1fr);
grid-template-rows: 1fr
}

@media screen and (min-width: 1200px){
height: 25vw;
}
`

const GridFurniture = styled.div`
display: grid;
grid-gap: 10px;

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: 45vh;
    
    @media screen and (min-width: 1100px){
      max-height: unset;
    }
}

div {
overflow: hidden;
}


@media screen and (min-width: 700px){
height: 80vh;
grid-template-columns: repeat(5, 1fr);
grid-template-rows: repeat(8, 1fr);
}

@media screen and (min-width: 1100px){
height: 120vh;
}
`

export {GridFurniture, GridMaterials, GridWoodenElements}