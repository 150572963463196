import styled from "styled-components";

const Box = styled.div`
  background-color: ${(props) => props.color || "white"};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  padding: 1.5rem 1rem;
  margin: 0.5rem 0;
`

export default Box;