import React from 'react';
import * as PropTypes from "prop-types";
import {Section} from "./PictureSection.styled";

const PictureSection = ({img, height, padding, children}) => {
    return (
        <Section img={img} height={height} padding={padding}>
            {children}
        </Section>
    );
};

PictureSection.propTypes = {
    img: PropTypes.string.isRequired,
    height: PropTypes.string
};


export default PictureSection