import React from 'react';
import PropTypes from 'prop-types';
import FlexContainer from "./styled/FlexContainer";
import H1 from "./styled/H1";
import H2 from "./styled/H2";
import {Fade} from "react-reveal";

const Header = ({title, subtitle, align, color}) => {
        return (
            <FlexContainer>
                <Fade bottom>
                    <H1 color={color}>{title}</H1>
                    <H2 color={color} align={align}>{subtitle}</H2>
                </Fade>
            </FlexContainer>
        );
    }
;

Header.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    color: PropTypes.string,
    align: PropTypes.string
};

export default Header;
