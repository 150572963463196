import styled from "styled-components";

const ContactSection = styled.div`
padding-top: 7rem;
background-color: ${({theme}) => theme.dirtyWhiteColor};
  
  @media screen and (min-width: 760px){
  padding-top: 8rem;
  }
  
  @media screen and (min-width: 1024px){
  padding-top: 10rem;
  }
`
export default ContactSection;