import React, {useState} from 'react';
import {FormGroup} from 'reactstrap'
import {useTranslation} from "react-i18next";
import * as emailjs from "emailjs-com";
import {StyledButton, StyledForm, StyledInput} from "./ContactForm.styled";

const ContactForm = () => {
    const {t} = useTranslation()
    const [form, setState] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    function emailAlert() {
        alert(t("contact.emailAlert"))
    }

    const handleSubmit = e => {
        e.preventDefault()
        const {name, email, subject, message} = form
        let templateParams = {
            from_name: name,
            email: email,
            to_name: 'montidrewno@gmail.com',
            subject: subject,
            message_html: message,
        }
        emailAlert();
        emailjs.send(
            'monti-mail',
            'template_113wjue',
            templateParams,
            'user_pcHFpjxRuyRifU1hs0be2'
        )
        resetForm()
    }


    const resetForm = () => {
        setState({
            name: '',
            email: '',
            subject: '',
            message: '',
        })
    }

    const handleChange = (e) => {
        const {value, name} = e.target
        setState({
            ...form,
            [name]: value
        })
    }

    return (
        <>
            <StyledForm onSubmit={handleSubmit}>
                <FormGroup controlId="formBasicEmail">
                    <StyledInput
                        type="email"
                        name="email"
                        value={form.email}
                        className="text-primary"
                        onChange={handleChange}
                        placeholder={t("contact.emailLabel")}
                    />
                </FormGroup>
                <FormGroup controlId="formBasicName">
                    <StyledInput
                        type="text"
                        name="name"
                        value={form.name}
                        className="text-primary"
                        onChange={handleChange}
                        placeholder={t("contact.nameLabel")}
                    />
                </FormGroup>
                <FormGroup controlId="formBasicSubject">
                    <StyledInput
                        type="text"
                        name="subject"
                        className="text-primary"
                        value={form.subject}
                        onChange={handleChange}
                        placeholder={t("contact.subjectLabel")}
                    />
                </FormGroup>
                <FormGroup controlId="formBasicMessage">
                    <StyledInput
                        type="textarea"
                        name="message"
                        className="text-primary"
                        value={form.message}
                        onChange={handleChange}
                        placeholder={t("contact.messageLabel")}
                    />
                </FormGroup>
                <StyledButton type="submit">
                    {t("contact.sendButton")}
                </StyledButton>
            </StyledForm>
        </>
    );
};

ContactForm.propTypes = {};

export default ContactForm;
