import React from 'react';
import Navbar from "../components/Navbar";
import PictureSection from "../components/PictureSection";
import Hero from "../images/services-hero.jpg"
import Header from "../components/Header";
import theme from "../theme";
import {Fade, Flip} from "react-reveal";
import Line from "../components/styled/Line";
import Section from "../components/styled/PageSection";
import {useTranslation} from "react-i18next";
import WhiteSection from "../components/WhiteSection";
import TurningOne from "../images/turning-1.png"
import TurningTwo from "../images/turning-2.JPG"
import TurningThree from "../images/turning-3.JPG"
import MillingOne from "../images/milling-1.JPG"
import MillingTwo from "../images/milling-2.jpg"
import LaserOne from "../images/laser-1.jpg"
import LaserTwo from "../images/services-laser-detail.jpg"
import PrintOne from "../images/print-1.jpg"
import PrintTwo from "../images/print-2.png"
import {GridImage, GridLaser, GridMilling, GridPrint, GridTurning} from "./Services.styled";
import ServiceName from "../components/ServiceName";

const Services = () => {
    const {t} = useTranslation();
    return (
        <Section>
            <Navbar dark={true}/>
            <PictureSection
                img={Hero}
                padding={'12% 10%'}
            >
                <div>
                    <Header
                        subtitle={t("services.hero")}
                        line={true}
                        align={'center'}
                        color={theme.dirtyWhiteColor}/>
                    <Flip left>
                        <Line/>
                    </Flip>
                </div>
            </PictureSection>
            <WhiteSection>
                <ServiceName title={t("services.turning")} subtitle={t("services.turningDescription")}/>
                <Fade>
                    <GridTurning>
                        <GridImage className={"turningOne"} src={TurningOne}/>
                        <GridImage className={"turningTwo"} src={TurningTwo}/>
                        <GridImage className={"turningThree"} src={TurningThree}/>
                    </GridTurning>
                </Fade>
                <ServiceName title={t("services.milling")} subtitle={t("services.millingDescription")}/>
                <Fade>
                    <GridMilling>
                        <GridImage className={"millingOne"} src={MillingOne}/>
                        <GridImage className={"millingTwo"} src={MillingTwo}/>
                    </GridMilling>
                </Fade>
                <ServiceName title={t("services.laser")} subtitle={t("services.laserDescription")}/>
                <Fade>
                    <GridLaser>
                        <GridImage className={"laserTwo"} src={LaserTwo}/>
                        <GridImage className={"laserOne"} src={LaserOne}/>
                    </GridLaser>
                </Fade>
                <ServiceName title={t("services.print")} subtitle={t("services.printDescription")}/>
                <Fade>
                    <GridPrint>
                        <GridImage className={"printOne"} src={PrintOne}/>
                        <GridImage className={"printTwo"} src={PrintTwo}/>
                    </GridPrint>
                </Fade>
            </WhiteSection>
        </Section>
    );
};

Services.propTypes = {};

export default Services;
