import {createGlobalStyle} from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  *,
  *::before,
  *::after {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
  }
 
  body{
  color: #222222;
  font-family: "Roboto Light", Tahoma, 'sans-serif';
  max-width:100%;
  margin-bottom: 10vh;
  }
  `