import styled from "styled-components";
import theme from "../theme";

const HomeHeader = styled.h1`
color: white;
text-align: center;
margin: 1.5rem 2rem;
font-size: 20px;
font-weight: 100;
text-decoration: none;
line-height: 2rem;

@media screen and (min-width: 600px){
 margin: 1.5rem 4rem;
}

@media screen and (min-width: 750px){
 margin: 1.5rem 6rem;
 font-size: 22px;
}

@media screen and (min-width: 900px){
 margin: 1.5rem 11rem;
}

@media screen and (min-width: 1050px){
 margin: 1.5rem 12rem;
 font-size: 26px;
}

@media screen and (min-width: 1200px){
 margin: 1.5rem 25rem;
}

@media screen and (min-width: 1600px){
 margin: 1.5rem 30rem;
 font-size: 32px;
 line-height: 2.5rem;
}
`

const HomeCover = styled.div`
background-color: ${theme.greyColor};
min-height: 100vh;
min-width: 100%;
max-width: 100vw;
position: fixed;
color: white;
display: flex;
flex-direction: column;
justify-content: center;
font-size: 20px;
line-height: 10vh;
font-family: "Roboto Thin",sans-serif;
z-index: 1500;
padding: 0 2rem;
`

export {HomeCover, HomeHeader}