import styled from "styled-components";
import Image from "../components/Image";

const GridServices = styled.div`
    width: 100vw;
    display: grid;
    grid-gap: 0.2rem;
    grid-template-rows: repeat(10, 1fr);
    grid-template-columns: repeat(10, 1fr);
    transform: translateX(-2rem);
    
    @media screen and (min-width: 750px){
    grid-gap: 0.5rem;
    width: initial;
    transform: none;
    }
    
    @media screen and (min-width: 1024px){
    width: 80%;
    margin-left: 20%;
    }
`

const GridTurning = styled(GridServices)`
  height: 60vh;
  
    @media screen and (min-width: 1280px){
    height: 45vw;
    }
`

const GridMilling = styled(GridServices)`
  height: 40vh;
  
  @media screen and (min-width: 1280px){
    height: 33vw;
    }
`

const GridLaser = styled(GridServices)`
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: 1fr;
    height: 90vh;
  
  @media screen and (min-width: 700px){
    grid-template-rows: repeat(10, 1fr);
    grid-template-columns: repeat(6, 1fr);
    height: 90vw;
  }
  
  @media screen and (min-width: 1024px){
    height: 50vw;
  }
  
  @media screen and (min-width: 1024px){
    height: 40vw;
  }
`

const GridPrint = styled(GridServices)`
  height: 50vh;
  
  @media screen and (min-width: 1024px){
      height: 50vw;
  }
  
    @media screen and (min-width: 1280px){
      height: 33vw;
  }
`

const GridImage = styled(Image)`

    img {
        object-fit: cover;
    }
    
    &.turningOne{
        grid-row-start: 1;
        grid-row-end: 9;
        grid-column-start: 5;
        grid-column-end: 11;
    }
    
    &.turningTwo{
        grid-row-start: 7;
        grid-row-end: 12;
        grid-column-start: 1;
        grid-column-end: 9;
        
        @media screen and (min-width: 700px){
          grid-column-end: 8;
  }
    }

    &.turningThree{
        grid-row-start: 4;
        grid-row-end: 7;
        grid-column-start: 2;
        grid-column-end: 5;
    }
    
    &.millingOne{
        grid-row-start: 1;
        grid-row-end: 10;
        grid-column-start: 5;
        grid-column-end: 11;
    }
    
    &.millingTwo{
        grid-row-start: 5;
        grid-row-end: 11;
        grid-column-start: 1;
        grid-column-end: 5;
    }
    
    &.printOne{
        grid-row-start: 2;
        grid-row-end: 10;
        grid-column-start: 4;
        grid-column-end: 11;
    }
    
    &.printTwo{
        grid-row-start: 1;
        grid-row-end: 5;
        grid-column-start: 1;
        grid-column-end: 6;
    }
    
    @media screen and (min-width: 700px){
    &.laserOne{
        grid-row-start: 1;
        grid-row-end: 7;
        grid-column-start: 1;
        grid-column-end: 5;
    }
    
    &.laserTwo{
        grid-row-start: 5;
        grid-row-end: 11;
        grid-column-start: 2;
        grid-column-end: 7;
    }
}
`

export {GridTurning, GridLaser, GridMilling, GridPrint, GridImage}