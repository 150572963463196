import React from 'react';
import {LogoLink, StyledLogo} from "./Logo.styled";
import * as PropTypes from "prop-types";

const Logo = ({src, alt}) => {
    return (
        <LogoLink to={"/"}>
            <StyledLogo src={src} alt={alt}/>
        </LogoLink>
    );
};

Logo.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
};

export default Logo;
